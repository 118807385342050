<template>
  <el-popover
    placement="bottom"
    trigger="click"
    popper-class="addressSelectorForPhone"
    :visible-arrow="false"
    @click.stop="() => {}"
    v-model="addressSelectorForPhoneFlag"
  >
    <div class="popoverContent">
      <div class="popoveHeader">
        <div class="inputBox">
          <span class="iconfont_Me icon-search iconStyle"></span>
          <el-input v-model="searchText" placeholder=""></el-input>
        </div>
      </div>
      <div class="selectorBox" v-if="searchCountries.length === 0">
        <div class="selectorLeft">
          <div
            class="selectorItem"
            v-for="(item, index) in countriesData"
            :key="index"
            :class="
              selectedIndexForLeft === index ? 'hasSelectedStyleForLeft' : ''
            "
            @click.stop="selectLeftItem(index)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="selectorRight">
          <div
            class="selectorItem"
            v-for="(item, index) in rightSidebarCountries"
            :key="item.sort + index"
            @click.stop="selectThisAreaCode(item.sort)"
          >
            <div class="itemLeft">
              <div class="nationalFlagBox">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="
                    require(`../../../assets/images/newCountrys/country_flag_${item.value}.png`)
                  "
                  :fit="'cover'"
                ></el-image>
              </div>
              <div class="nationalNameBox" :title="item.label">
                {{ item.label }}
              </div>
            </div>
            <div class="itemRight">
              {{ `+${item.sort}` }}
            </div>
          </div>
        </div>
      </div>
      <div class="searchBox" v-else>
        <div
          class="selectorItem"
          v-for="(item, index) in searchCountries"
          :key="item.sort + index"
          @click.stop="selectThisAreaCode(item.sort)"
        >
          <div class="itemLeft">
            <div class="nationalFlagBox">
              <el-image
                style="width: 100%; height: 100%"
                :src="
                  require(`../../../assets/images/newCountrys/country_flag_${item.value}.png`)
                "
                :fit="'cover'"
              ></el-image>
            </div>
            <div class="nationalNameBox" :title="item.label">
              {{ item.label }}
            </div>
          </div>
          <div class="itemRight">
            {{ `+${item.sort}` }}
          </div>
        </div>
      </div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
export default {
  name: "addressSelectorForPhone",
  computed: {
    // 处理过的国家数据
    countriesData() {
      return this.$store.state.homePage.sortedCountriesData;
    },
  },
  data() {
    return {
      // 搜索关键词
      searchText: "",
      // 被选中的大洲下标
      selectedIndexForLeft: 0,
      // 当前需要展示的右侧国家集合
      rightSidebarCountries: [],
      // 搜索出来的国家集合
      searchCountries: [],
      // 气泡弹窗状态
      addressSelectorForPhoneFlag: false,
    };
  },
  watch: {
    // 监听关键词变化
    searchText: {
      handler(val, old) {
        if (val !== "" && val !== old) {
          let searchCountries = [];
          this.countriesData.forEach((element) => {
            let catchValue = element.children.filter(
              (item) => item.label.indexOf(val) !== -1
            );
            if (catchValue && catchValue.length !== 0) {
              searchCountries = searchCountries.concat(catchValue);
            }
          });
          this.searchCountries = searchCountries;
        } else {
          this.searchCountries = [];
        }
      },
      deep: true,
    },
    // 监听被选中的大洲下标
    selectedIndexForLeft: {
      handler(val, old) {
        if (val != old) {
          if (this.countriesData.length != 0) {
            this.rightSidebarCountries = this.countriesData[val].children;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 选择左侧大洲
    selectLeftItem(index) {
      this.selectedIndexForLeft = index;
    },
    // 选择这个区号
    selectThisAreaCode(areaCode) {
      this.$emit("selectThisAreaCode", areaCode);
      this.addressSelectorForPhoneFlag = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.addressSelectorForPhone
  .popoverContent
    width 100%;
    overflow hidden;
    .popoveHeader
      height 60px;
      box-sizing border-box;
      border-bottom 1px solid #F0F0F0;
      padding 13px 20px;
      display flex;
      align-items center;
      justify-content center;
      .inputBox
        height 34px;
        width 100%;
        background #F7F7F7;
        display flex;
        align-items center;
        justify-content flex-start;
        .iconStyle
          margin-left 15px;
          font-size 18px;
          color #BFBFBF;
    .selectorBox
      width 100%;
      height 340px;
      overflow hidden;
      display flex;
      align-items center;
      justify-content flex-start;
      .selectorLeft
        height 100%;
        width 130px;
        flex-shrink 0;
        background #F7F7F7;
        .selectorItem
          width 100%;
          height 40px;
          font-size 14px;
          color #333333;
          cursor pointer;
          user-select none;
          display flex;
          align-items center;
          justify-content center;
        .hasSelectedStyleForLeft
          background #FFFFFF;
          color #33CC66;
      .selectorRight
        flex 1;
        min-width 0;
        height 100%;
        box-sizing border-box;
        padding 0 20px;
        overflow-x hidden;
        overflow-y auto;
        &::-webkit-scrollbar
          width 4px;
        &::-webkit-scrollbar-thumb
          border-radius: 2px;
          box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
          background: #ADADAD;
        &::-webkit-scrollbar-track
          box-shadow: inset 0 0 5px rgba(0,0,0,0);
          border-radius: 0;
          background: transparent;
        .selectorItem
          width 100%;
          height 50px;
          cursor pointer;
          user-select none;
          box-sizing border-box;
          border-bottom 1px dashed #e4e5e9;
          display flex;
          align-items center;
          justify-content space-between;
          &:last-child
            border-bottom none !important;
          .itemLeft
            flex 1;
            min-width 0;
            height 100%;
            display flex;
            padding-left 5px;
            align-items center;
            box-sizing border-box;
            justify-content flex-start;
            .nationalFlagBox
              width 22px;
              height 22px;
              border-radius 50%;
              overflow hidden;
              flex-shrink 0;
              margin-right 5px;
            .nationalNameBox
              flex 1;
              min-width 0;
              height 100%;
              font-size 14px;
              color #200E32;
              display flex;
              align-items center;
              justify-content flex-start;
              line-height 20px;
              overflow hidden;
          .itemRight
            height 100%;
            min-width 0;
            flex-shrink 0;
            color #333333;
            padding 0 5px;
            font-size 14px;
            overflow hidden;
            line-height 50px;
            text-align center;
            box-sizing border-box;
    .searchBox
      width 100%;
      height 340px;
      overflow hidden;
      box-sizing border-box;
      padding 0 20px;
      overflow-x hidden;
      overflow-y auto;
      &::-webkit-scrollbar
        width 4px;
      &::-webkit-scrollbar-thumb
        border-radius: 2px;
        box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
        background: #ADADAD;
      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px rgba(0,0,0,0);
        border-radius: 0;
        background: transparent;
      .selectorItem
        width 100%;
        height 50px;
        cursor pointer;
        user-select none;
        box-sizing border-box;
        border-bottom 1px dashed #e4e5e9;
        display flex;
        align-items center;
        justify-content space-between;
        &:last-child
          border-bottom none !important;
        .itemLeft
          flex 1;
          min-width 0;
          height 100%;
          display flex;
          padding-left 5px;
          align-items center;
          box-sizing border-box;
          justify-content flex-start;
          .nationalFlagBox
            width 20px;
            height 20px;
            border-radius 50%;
            overflow hidden;
            flex-shrink 0;
            margin-right 5px;
            border 1px solid #F0F0F0;
          .nationalNameBox
            flex 1;
            min-width 0;
            height 100%;
            font-size 14px;
            color #200E32;
            display flex;
            align-items center;
            justify-content flex-start;
            line-height 20px;
            overflow hidden;
        .itemRight
          height 100%;
          min-width 0;
          flex-shrink 0;
          color #333333;
          padding 0 5px;
          font-size 14px;
          overflow hidden;
          line-height 50px;
          text-align center;
          box-sizing border-box;
</style>
<style lang="stylus">
.addressSelectorForPhone
  width 368px !important;
  min-width 368px !important;
  padding 0 !important;
  .popoverContent
    .el-input
      .el-input__inner
        background transparent !important;
        border none !important;
        height 34px !important;
        line-height 34px !important;
        font-size 14px !important;
        color #200E32 !important;
</style>
